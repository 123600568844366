import React, { Component } from "react";
// import { Link, navigate } from 'gatsby'
import Header from "./header"
// import OurTeam from "../../images/our-team.png";
import EnterpriseSoftware from "../../images/enterprise-software.png";
import DriverTablets from "../../images/driver-tablets.png";
import DispatchingSystem from "../../images/dispatching-system.png";
import Sensors from "../../images/sensors.png";
import Live from "../../images/live-eta.png";

class Technologypage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        {/* <Header/>
        <div className="default-head technology-hero d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className="default-head--title">Our Technology</h1>
              </div>
            </div>
          </div>
        </div>

        <section className="section-enterprise-software enterprise-software-background">
          <div className="container">
            <div className="row align-items-lg-center">
              <div className="col-md-6">
                <div className="">
                  <img className="img-fluid" src={EnterpriseSoftware} alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <h2 className="section-title">Enterprise Software</h2>
                  <p className="section-subtitle">
                    No one has done what we have. Our custom platform is the
                    only comprehensive, end-to-end solution that involves the
                    entire dumpster delivery operation. We fully integrated our
                    cloud based ordering platform which allows us to collect
                    high level data points that no other debris company can. This
                    allows us to efficiently communicate with our customers and
                    provide the highest level of service and optimization.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-" id="dt">
          <div className="container">
            <div className="row align-items-lg-center">
              <div className="col-md-6 order-md-1">
                <div className="">
                  <img className="img-fluid" src={DriverTablets} alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <h2 className="section-title">Driver Tablets</h2>
                <p className="section-subtitle">
                  All of our drivers carry tablets, so they can instantaneously
                  update information on the site to ensure Curbside’s data is
                  always accurate.
                </p>
              </div>

            </div>
          </div>
        </section>
        <section className="section-" id="ds">
          <div className="container">
            <div className="row align-items-lg-center">
              <div className="col-md-6">
                <div className="">
                  <img className="img-fluid" src={DispatchingSystem} alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <h2 className="section-title">Dispatching System</h2>
                <p className="section-subtitle">
                  Our enterprise ordering system feeds into a stand alone
                  dispatching software. All of the order information is fully
                  visible and cultivated with data to make the best routing
                  decision. Our software helps the dispatcher make the optimal
                  route day to dat while also being exremely agile for any
                  changes.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section-" id="sensor">
          <div className="container">
            <div className="row align-items-lg-center">
              <div className="col-md-6 order-md-1">
                <div className="">
                  <img className="img-fluid" src={Sensors} alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <h2 className="section-title">Sensors</h2>
                <p className="section-subtitle">
                  These sensors are equipped with GPS to provide the exact
                  location of the dumpster, allow us to monitor the fullness of
                  the dumpster, and receive a live feed of what is being dumped.
                </p>
              </div>

            </div>
          </div>
        </section>
        <section className="section-">
          <div className="container">
            <div className="row align-items-lg-center">
              <div className="col-md-6">
                <div className="">
                  <img className="img-fluid" src={Live} alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <h2 className="section-title">Cloud based GPS & Live ETA</h2>
                <p className="section-subtitle">
                  Safety and efficiency are our main priorities. We use a fleet
                  management software to live track Curbside trucks so you know
                  when they will arrive and we ensure they are driving safe.
                </p>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    );
  }
}

export default Technologypage;
