import React from "react"
import Layout from "../components/layout"
import Technology from "../components/marketing/technology"
import SEO from "../components/seo"

const TechnologyPage = () => (
  <Layout>
    <SEO title="Our Technology" />
    <Technology/>
  </Layout>
)

export default TechnologyPage
